/* eslint-disable react-hooks/exhaustive-deps */
import { useState, MouseEvent, ChangeEvent, useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Grid } from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import CssContainer from 'components/CssContainer';
import Filters from 'components/Filters';
import CssTable from 'components/CssTable';
import ScreenDialog from 'components/ScreenDialog';
import CssAccordion from 'components/CssAccordion';
import CssButton from 'components/CssButton';
import CssAutocomplete from 'components/CssAutocomplete';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import { Order, checkPermissions } from 'utils';
import { SettlementReportsHeaders } from 'constants/settlement-reports-table-head-columns';
import { GlobalContext } from 'context';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: 32,
  marginTop: 60,
  marginBottom: 0,
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  paddingBottom: 14
}));

const Settlement = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { settlementReportsFilters, setSettlementReportsFilters, searchQuery, isSearchClosed, isUpdatePage, setIsUpdatePage, filterDates, setFilterDates, isFilterCleared, setIsFilterCleared, reportBatchIds, setReportBatchIds, setIsReportFilterApplied, isChangeReportBatchIds, setIsChangeReportBatchIds, isFilterApplied, setIsFilterApplied, setIsFilterIconDisplay, userPermissions } = useContext(GlobalContext);
  const { getSettlementsApi, getSettlementsDownloadApi, getSettlementsListApi } = useRequests();
  const getSettlements = useApi(getSettlementsApi);
  const getSettlementsDownload = useApi(getSettlementsDownloadApi);
  const getSettlementsList = useApi(getSettlementsListApi);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState<any>([]);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('date');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openFiltersScreen, setOpenFiltersScreen] = useState(false);
  const [isFiltersCleared, setIsFiltersCleared] = useState(false);
  const [enableButtons, setEnableButtons] = useState(true);
  const [filterQuery, setFilterQuery] = useState('');
  const [isDateFilterApplied, setIsDateFilterApplied] = useState(false);
  const [reportBatchMenu, setReportBatchMenu] = useState<any>([]);
  const [reportBatchMenuPage, setReportBatchMenuPage] = useState(0);
  const [reportBatchMenuLimit] = useState(50);

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilters = () => {
    setOpenFiltersScreen(true);
  };

  const onFiltersScreenClose = (event: {},
  reason: 'backdropClick') => {
    setOpenFiltersScreen(false);
  };

  const onClearFilters = () => {
    setFilterDates({ fromDate: '', toDate: '' });
    setIsFiltersCleared(true);
    setFilterQuery('');
    setIsFilterApplied(false);
    setIsFilterIconDisplay(false);
    enqueueSnackbar('All filters have been cleared successfully.', {
      variant: 'defaultAlert'
    });
    setSettlementReportsFilters((csf: any) => _.map(csf, (c) => {
      c.filters = _.map(c.filters, (f: any) => {
        if (f.isChecked) {
          f.isChecked = false;
        }
        return f;
      });
      return c;
    }));
    setIsFilterCleared(true);
    setReportBatchIds([]);
  };

  const onReportBatchScroll = (event: any) => {
    const element = event.target;

    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      setReportBatchMenuPage(reportBatchMenuPage + 1);
    }
  };

  const onApply = () => {
    let query = '';
    setOpenFiltersScreen(false);
    setEnableButtons(true);
    setIsFilterApplied(true);
    setIsFilterIconDisplay(true);
    _.map(settlementReportsFilters, (o: any) => {
      _.map(o.filters, (f: any) => {
        if (f.isChecked) {
          query += `&${o.parentKey}=${f.key}`;
        }
      });
    });

    if (reportBatchIds.length > 0) {
      _.map(reportBatchIds, (b: any) => {
        query += `&settlementId=${b.value}`;
      });

      setIsReportFilterApplied(true);
    }

    if(query) {
      setFilterQuery(query);
    }
    if (filterDates && filterDates.fromDate && filterDates.toDate) {
      setIsDateFilterApplied(true);
    }
    setIsUpdatePage(true);
  };

  const onCancel = () => {
    setOpenFiltersScreen(false);
    setEnableButtons(true);
  };

  const onReportDownload = (d: any) => {
    getSettlementsDownload.request(d.id).then((res) => {
      if (res.status === 200) {
        const link = document.createElement('a');
        link.href = res.data.downloadUrl;
        document.body.appendChild(link);
        link.click();
      }
    });
  };

  const checkReportSettlementPermissions = () => {
    return checkPermissions(userPermissions, 'REPORTS');
  };

  useEffect(() => {
    const newFilters = _.map(settlementReportsFilters, o => {
      if (_.findIndex(o.filters, (f: any) => f.isChecked) !== -1) {
        return true;
      }
      return false;
    });
    const isEnabled = _.compact(newFilters)[0];

    if (isFiltersCleared && !isEnabled) {
      setEnableButtons(true);
    } else if (isEnabled === true) {
      setEnableButtons(false);
    } else if (filterDates && filterDates.fromDate && filterDates.toDate) {
      setEnableButtons(false);
    } else if (isChangeReportBatchIds === true) {
      setEnableButtons(false);
    } else {
      setEnableButtons(true);
    }
  }, [settlementReportsFilters, isFiltersCleared, filterDates, isChangeReportBatchIds]);

  useEffect(() => {
    if (!searchQuery) {
      setPage(isUpdatePage ? 0 : page);
      getSettlements.request(page + 1, rowsPerPage, order === 'asc' ? 1 : -1, orderBy, filterDates.fromDate, filterDates.toDate, filterQuery, 'REPORTS_SETTLEMENTS').then((res) => {
        if (res.status === 200) {
          const { totalcount } = res.headers;
          setTotalCount(parseInt(totalcount));
          setData(res.data);
          setIsDateFilterApplied(false);

          if (isFilterApplied) {
            enqueueSnackbar('Filters have been applied successfully.', {
              variant: 'successWithUndo',
              onUndo: onClearFilters
            });
            setIsFilterApplied(false);
          }
        }
        if (isUpdatePage) setIsUpdatePage(false);
        if (isFilterCleared) setIsFilterCleared(false);
        if (isFiltersCleared) setIsFiltersCleared(false);
        if (isChangeReportBatchIds) setIsChangeReportBatchIds(false);
      });
    } else if (searchQuery !== '' && !isSearchClosed) {
      setPage(isUpdatePage ? 0 : page);
      getSettlements.request(page + 1, rowsPerPage, order === 'asc' ? 1 : -1, orderBy, filterDates.fromDate, filterDates.toDate, filterQuery, 'REPORTS_SETTLEMENTS', searchQuery).then((res) => {
        if (res.status === 200) {
          const { totalcount } = res.headers;
          setTotalCount(parseInt(totalcount));
          setData(res.data);
          setIsDateFilterApplied(false);

          if (isFilterApplied) {
            enqueueSnackbar('Filters have been applied successfully.', {
              variant: 'successWithUndo',
              onUndo: onClearFilters
            });
            setIsFilterApplied(false);
          }
        }
        if (isUpdatePage) setIsUpdatePage(false);
        if (isFilterCleared) setIsFilterCleared(false);
        if (isFiltersCleared) setIsFiltersCleared(false);
        if (isChangeReportBatchIds) setIsChangeReportBatchIds(false);
      });
    }
  }, [page, rowsPerPage, order, orderBy, searchQuery, filterQuery, isFilterCleared, isFiltersCleared, isDateFilterApplied]);

  useEffect(() => {
    getSettlementsList.request(reportBatchMenuPage + 1, reportBatchMenuLimit, 'REPORTS_SETTLEMENTS').then((res) => {
      if (res.status === 200 && res.data.length > 0) {
        setReportBatchMenu((rps: any) => {
          let o: any[] = [];
          _.map(res.data, (item, i) => {
            if (!_.includes(o, item.settlementId)) {
              o.push(item);
            }
          });

          return _.map(o, (item, i) => {
            return {value: item.settlementId, displayName: item.settlementId};
          })
        })
      }
    });
  }, [reportBatchMenuPage]);

  return (
    <CssContainer sx={{p: '0 !important', mt: 0}}>
      <CssTable
        headers={SettlementReportsHeaders}
        totalCount={totalCount}
        data={data}
        order={order}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        onRequestSort={handleRequestSort}
        onTablePageChange={handleChangePage}
        onTableRowsPerPageChange={handleChangeRowsPerPage}
        onFilters={handleFilters}
        isDesktopFilters={true}
        sx={{ mt: '0 !important' }}
        rowActions={{
          onDownloadAction: onReportDownload
        }}
        permissionCheck={checkReportSettlementPermissions()}
        highlightText={searchQuery}
        rowClassName={'no-row-click'}
      />
      <ScreenDialog
        title={'Filters'}
        hideCloseBtn={true}
        disableEscapeKeyDown={true}
        open={openFiltersScreen}
        onScreenClose={onFiltersScreenClose}
      >
        <StyledBox>
          <Typography variant='body2' sx={{fontWeight: 500}}>Filters</Typography>
          <Typography variant='body2' sx={{cursor: 'pointer'}} onClick={onClearFilters}>&mdash; Clear filters</Typography>
        </StyledBox>
        <Box sx={{m: 4, mt: 0}}>
          <CssAccordion title='Date' isExpand={filterDates && filterDates.fromDate && filterDates.toDate}>
            <Filters from={'POP_IN_FILTER'} fromLabel={'From'} toLabel={'To'} />
          </CssAccordion>

           <CssAccordion title='Report batch' isExpand={reportBatchIds.length > 0}>
            <CssAutocomplete label='Select report batch' placeholder='Select report batch' options={reportBatchMenu} onScroll={onReportBatchScroll} />
          </CssAccordion>

          <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'center', mt: 6.5 }}>
              <CssButton variant='outlined' sx={{width: '46.5%', mr: 1, ml: 0}} onClick={onCancel} disabled={enableButtons}>Cancel</CssButton>
              <CssButton variant='contained' sx={{width: '46.5%', mr: 0, ml: 1}} onClick={onApply} disabled={enableButtons}>Apply</CssButton>
            </Grid>
          </Grid>
        </Box>
      </ScreenDialog>
    </CssContainer>
  );
};

export default Settlement;