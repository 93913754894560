/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect, MouseEvent, ChangeEvent } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Grid, FormGroup, FormControlLabel } from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { Order, setAccordionExpandByFilters } from 'utils';
import CssTable from 'components/CssTable';
import ScreenDialog from 'components/ScreenDialog';
import CssDialog from 'components/CssDialog';
import CssAccordion from 'components/CssAccordion';
import CssCheckbox from 'components/CssCheckbox';
import CssButton from 'components/CssButton';
import Filters from 'components/Filters';
import FilterButton from 'components/FilterButton';
import CommentDialog from 'components/CommentDialog';
import CheckTick from 'components/icons/CheckTick';
import { NIFundReceivedHeaders } from 'constants/transaction-history-table-head-columns';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import { GlobalContext } from 'context';
import { checkPermissions } from 'utils';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: 32,
  marginTop: 60,
  marginBottom: 0,
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  paddingBottom: 14
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginBottom: 5,
}));

const NIFundReceived = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { transactionsHistoryFilters, setTransactionsHistoryFilters, filterDates, setFilterDates, isFilterCleared, setIsFilterCleared, isUpdatePage, setIsUpdatePage, isFilterApplied, setIsFilterApplied, setIsFilterIconDisplay, userPermissions, selectedButtonType, setSelectedButtonType } = useContext(GlobalContext);
  const { getNIFundReceivedApi, NIfundupdateSettlementsByIdApi, NIFundupdateSettlementStatusByIdApi, postCommentsByThreadIdApi, getCommentsByThreadIdApi } = useRequests();
  const getNIFundReceived = useApi(getNIFundReceivedApi);
  const updateSettlementsById = useApi(NIfundupdateSettlementsByIdApi);
  const updateSettlementStatusById = useApi(NIFundupdateSettlementStatusByIdApi);
  const getCommentsByThreadId = useApi(getCommentsByThreadIdApi);
  const postCommentsByThreadId = useApi(postCommentsByThreadIdApi);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState<any>([]);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('startDate');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openFiltersScreen, setOpenFiltersScreen] = useState<boolean>(false);
  const [filterQuery, setFilterQuery] = useState('');
  const [isDateFilterApplied, setIsDateFilterApplied] = useState<boolean>(false);
  const [isFiltersCleared, setIsFiltersCleared] = useState(false);
  const [enableButtons, setEnableButtons] = useState(true);
  const [isLeanAndExinityAmountUpdated, setIsLeanAndExinityAmountUpdated] = useState(false);
  const [openReconStatusScreen, setOpenReconStatusScreen] = useState<boolean>(false);
  const [settlementId, setSettlementId] = useState<string>('');
  const [reconStatus, setReconStatus] = useState<string>('');
  const [reconStatusEnabled, setReconStatusEnabled] = useState<string>('DISCREPANCY');
  const [isReconStatusUpdated, setIsReconStatusUpdated] = useState<boolean>(false);
  const [openCommentsScreen, setOpenCommentsScreen] = useState<boolean>(false);
  const [selectedSettlement, setSelectedSettlement] = useState<any>(null);
  const [commentsData, setCommentsData] = useState<any>([]);
  const [isCommentAdded, setIsCommentAdded] = useState<boolean>(false);
  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onFiltersScreenClose = (event: {},
  reason: 'backdropClick') => {
    setOpenFiltersScreen(false);
  };

  const onClearFilters = () => {
    setFilterDates({ fromDate: '', toDate: '' });
    setIsFiltersCleared(true);
    setFilterQuery('');
    setIsFilterApplied(false);
    setIsFilterIconDisplay(false);
    enqueueSnackbar('All filters have been cleared successfully.', {
      variant: 'defaultAlert'
    });
    setTransactionsHistoryFilters((csf: any) => _.map(csf, (c) => {
      c.filters = _.map(c.filters, (f: any) => {
        if (f.isChecked) {
          f.isChecked = false;
        }
        return f;
      });
      return c;
    }));
    setIsFilterCleared(true);
  };

  const onApply = () => {
    let query = '';
    setOpenFiltersScreen(false);
    setEnableButtons(true);
    setIsFilterApplied(true);
    setIsFilterIconDisplay(true);
    _.map(transactionsHistoryFilters, (o: any) => {
      _.map(o.filters, (f: any) => {
        if (f.isChecked) {
          query += `&${o.parentKey}=${f.key}`;
        }
      });
    });

    if(query) {
      setFilterQuery(query);
    }
    if (filterDates && filterDates.fromDate && filterDates.toDate) {
      setIsDateFilterApplied(true);
    }
    setIsUpdatePage(true);
  };

  const onCancel = () => {
    setOpenFiltersScreen(false);
    setEnableButtons(true);
  };

  const onCheckboxClick = (event: ChangeEvent<HTMLInputElement>, key: string, index: number) => {
    const selectedFilters = _.find(transactionsHistoryFilters, (o) => o.parentKey === key).filters;
    const newFilters = selectedFilters.map((item: any) => {
      if (item.key === event.target.value) {
        item.isChecked = event.target.checked;
      }

      return item;
    });

    setTransactionsHistoryFilters((csf: any) => _.map(csf, (c) => {
      if (c.parentKey === key) {
        c.filters = newFilters;
      }
      return c;
    }));
  };

  const toggleEditButton = (row: any, key: string, isToggle: boolean) => {
    setData((ds: any) => _.map(ds, (d) => {
      if (row.id === d.id) {
        d[`${key}Hover`] = isToggle;
      }
      return d;
    }));
  };

  const toggleCommentsButton = (row: any, isToggle: boolean) => {
    setSelectedSettlement(row);
    setData((ds: any) => _.map(ds, (d) => {
      if (row.niDailySummaryId === d.niDailySummaryId) {
        d[`isCommentHover`] = isToggle;
      }
      return d;
    }));
  };

  const onAmountEditButtonClicked = (row: any, key: string) => {
    setData((ds: any) => _.map(ds, (d) => {
      if (row.niDailySummaryId === d.niDailySummaryId) {
        d[`${key}Edit`] = true;
      }
      return d;
    }));
  };

  const onAmountEditChangeField = (event: any, row: any, key: string) => {
    setData((ds: any) => _.map(ds, (d) => {
      if (row.niDailySummaryId === d.niDailySummaryId) {
        d[`${key}`] = event.target.value;
      }
      return d;
    }));
  };

  const onUpdateAmount = (event: any, row: any, key: string) => {
    if (event.key === 'Enter') {
      updateSettlementsById.request(row.niDailySummaryId, {
        [key]: event.target.value
      }).then((res) => {
        if (res.status === 200) {
          setIsLeanAndExinityAmountUpdated(true);
        }
      })
    }
  };

  const onReconStatusScreenOpen = () => {
    setOpenReconStatusScreen(true);
  };

  const onReconStatusScreenClose = (event: {},
  reason: 'backdropClick') => {
    setOpenReconStatusScreen(false);
  };

  const onReconStatusUpdate = (r: any) => {
    setSettlementId(r.niDailySummaryId);
    setReconStatus(r.status === 'DISCREPANCY' ? 'RESOLVED' : 'DISCREPANCY');
    setReconStatusEnabled(s => r.status);
    onReconStatusScreenOpen();
  };

  const onStatusUpdateConfirm = () => {
    const payload = {
      status: reconStatus,
    };

    updateSettlementStatusById.request(settlementId, payload).then((res) => {
      if (res.status === 200) {
        setIsReconStatusUpdated(true);
        setOpenReconStatusScreen(false);
        enqueueSnackbar('Record status has been updated successfully.', {
          variant: 'successAlert'
        });
      }
    });
  };

  const onCommentsScreenClose = (event: {},
    reason: 'backdropClick') => {
    setCommentsData([]);
    setOpenCommentsScreen(false);
  };

  const getComments = () => {
    getCommentsByThreadId.request(selectedSettlement.niDailySummaryId, 'NI_SUMMARY').then((res) => {
      if (res.status === 200) {
        setCommentsData((c: any) => res.data);
      }
    });
  };

  const onComment = () => {
    setOpenCommentsScreen(true);
    getComments();
  };

  const onAddComment = (value: string, setValue: any) => {
    postCommentsByThreadId.request(selectedSettlement.niDailySummaryId, {
      comment: value,
      threadType: 'NI_SUMMARY'
    }).then((res: any) => {
      if (res.status === 200) {
        setValue('');
        getComments();
        setIsCommentAdded(true)
      }
    })
  };

  const checkDailyReconPermissions = (isViewIgnored?: boolean | false) => {
    return checkPermissions(userPermissions, 'DASHBOARD_FIN_OPS', isViewIgnored);
  };

  useEffect(() => {
    const newFilters = _.map(transactionsHistoryFilters, o => {
      if (_.findIndex(o.filters, (f: any) => f.isChecked) !== -1) {
        return true;
      }
      return false;
    });
    const isEnabled = _.compact(newFilters)[0];

    if (isFiltersCleared && !isEnabled) {
      setEnableButtons(true);
    } else if (isEnabled === true) {
      setEnableButtons(false);
    } else if (filterDates && filterDates.fromDate && filterDates.toDate) {
      setEnableButtons(false);
    } else {
      setEnableButtons(true);
    }
  }, [transactionsHistoryFilters, isFiltersCleared, filterDates]);

  useEffect(() => {
    setPage(isUpdatePage ? 0 : page);
    getNIFundReceived.request(page + 1, rowsPerPage, order === 'asc' ? 1 : -1, filterDates.fromDate, filterDates.toDate, filterQuery ,'NI_FUND_RECEIVED').then((res) => {
      if (res.status === 200) {
        const { totalcount } = res.headers;
        setTotalCount(parseInt(totalcount));
        setData((ds: any) => {
          return _.map(res.data, (d) => {
            d.leanAmountEdit = false;
            d.exinityAmountEdit = false;
            d.leanAmountHover = false;
            d.exinityAmountHover = false;
            return d;
          });
        });
        setIsDateFilterApplied(false);

        if (isFilterApplied) {
          enqueueSnackbar('Filters have been applied successfully.', {
            variant: 'successWithUndo',
            onUndo: onClearFilters
          });
          setIsFilterApplied(false);
        }
      }
      if (isUpdatePage) setIsUpdatePage(false);
      if (isFilterCleared) setIsFilterCleared(false);
      if (isFiltersCleared) setIsFiltersCleared(false);
      if (isLeanAndExinityAmountUpdated) setIsLeanAndExinityAmountUpdated(false);
      if (isReconStatusUpdated) setIsReconStatusUpdated(false);
      if (isCommentAdded) setIsCommentAdded(false);
    });
  }, [page, rowsPerPage, order, orderBy, isFilterCleared, filterQuery, isFiltersCleared, isUpdatePage, isDateFilterApplied, isLeanAndExinityAmountUpdated, isReconStatusUpdated, isCommentAdded]);

  return (
    <>
      <CssTable
        headers={NIFundReceivedHeaders}
        totalCount={totalCount}
        data={data}
        order={order}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        onRequestSort={handleRequestSort}
        onTablePageChange={handleChangePage}
        onTableRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ mt: {md: `0 !important`, xs: 0} }}
        rowClassName='no-row-click'
        onToggleCommentsHover={toggleCommentsButton}
        {...(checkDailyReconPermissions() && {
          onToggleRowEditHover: toggleEditButton,
          onEditKeyEnter: onUpdateAmount,
          onEditButtonClicked: onAmountEditButtonClicked,
          onEditChangeField: onAmountEditChangeField,
          onStatusAction: onReconStatusUpdate
        })}
        from={'RECON'}
        rowActions={{
          onCommentsAction: onComment,
          ignoreView: checkDailyReconPermissions(true)
        }}
        permissionCheck={checkDailyReconPermissions()}
      />

      <ScreenDialog
        title={'Filters'}
        hideCloseBtn={true}
        disableEscapeKeyDown={true}
        open={openFiltersScreen}
        onScreenClose={onFiltersScreenClose}
      >
        <StyledBox>
          <Typography variant='body2' sx={{fontWeight: 500}}>Filters</Typography>
          <Typography variant='body2' sx={{cursor: 'pointer'}} onClick={onClearFilters}>&mdash; Clear filters</Typography>
        </StyledBox>
        <Box sx={{m: 4, mt: 0}}>
          {transactionsHistoryFilters && transactionsHistoryFilters.map((item: any, index: number) => (
              <CssAccordion title={item.title} key={index} isExpand={setAccordionExpandByFilters(item.filters)}>
                <FormGroup>
                  {item.filters.map((filter: any, i: number) => {
                    return (
                      <StyledFormControlLabel control={<CssCheckbox onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckboxClick(e, item.parentKey, i)} />} label={filter.label} checked={filter.isChecked} key={i} value={filter.key}  />
                    )
                  })}
                </FormGroup>
              </CssAccordion>
            ))}

          <CssAccordion title='Date' isExpand={filterDates && filterDates.fromDate && filterDates.toDate}>
            <Filters from={'POP_IN_FILTER'} fromLabel={'From'} toLabel={'To'} />
          </CssAccordion>

          <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'center', mt: 6.5 }}>
              <CssButton variant='outlined' sx={{width: '46.5%', mr: 1, ml: 0}} onClick={onCancel} disabled={enableButtons}>Cancel</CssButton>
              <CssButton variant='contained' sx={{width: '46.5%', mr: 0, ml: 1}} onClick={onApply} disabled={enableButtons}>Apply</CssButton>
            </Grid>
          </Grid>
        </Box>
      </ScreenDialog>

      <CssDialog
        disableEscapeKeyDown={true}
        open={openReconStatusScreen}
        onScreenClose={onReconStatusScreenClose}
      >
        <StyledBox sx={{mt: 4.375, ml: 5.625, mr: 5.625}}>
          <Typography variant='h6'>Record status</Typography>
        </StyledBox>

        <Box sx={{pl: 5.625, pr: 5.625, pb: 5.25}}>
          {reconStatusEnabled === 'DISCREPANCY' ?
            <FilterButton className={'active btn-filter btn-flat custom btn-transaction-status'} sx={{pt: 1, pb: 1}}>Resolved <CheckTick /></FilterButton>
            : 
            <FilterButton className={'active btn-filter btn-flat custom btn-transaction-status'} sx={{pt: 1, pb: 1}}>Discrepancy <CheckTick /></FilterButton>
          }
          
          <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'center', mt: 4, justifyContent: 'space-between', display: 'flex' }}>
              <CssButton variant='outlined' sx={{width: '35%', mr: 1, ml: 0}} onClick={onReconStatusScreenClose}>Cancel</CssButton>
              <CssButton variant='contained' sx={{width: '35%', mr: 0, ml: 1}} onClick={onStatusUpdateConfirm} className='active'>Confirm</CssButton>
            </Grid>
          </Grid>
        </Box>
      </CssDialog>

      <CommentDialog
        open={openCommentsScreen}
        data={commentsData}
        permissions={checkDailyReconPermissions(true)}
        onCommentsScreenClose={onCommentsScreenClose}
        onAddComment={onAddComment}
      />
    </>
  );
};

export default NIFundReceived;