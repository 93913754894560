/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect, MouseEvent } from 'react';
import { Box } from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { Order} from 'utils';
import CssContainer from 'components/CssContainer';
import CssTitle from 'components/CssTitle';
import CssFlexBox from 'components/CssFlexBox';
import ButtonGroup from 'components/ButtonGroup';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import { GlobalContext } from 'context';
import DWTransactions from './DWTransactions';
import WalletTransactions from './WalletTransactions';
import NIFundReceived from './NIFundReceived';

const TransactionsHistory = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { transactionsHistoryFilters, setTransactionsHistoryFilters, filterDates, setFilterDates, isFilterCleared, setIsFilterCleared, isUpdatePage, setIsUpdatePage, isFilterApplied, setIsFilterApplied, setIsFilterIconDisplay, selectedButtonType, setSelectedButtonType } = useContext(GlobalContext);
  const { getDWTransactionsApi } = useRequests();
  const getDWTransactions = useApi(getDWTransactionsApi);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState<any>([]);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('startDate');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterQuery, setFilterQuery] = useState('');
  const [isDateFilterApplied, setIsDateFilterApplied] = useState<boolean>(false);
  const [isFiltersCleared, setIsFiltersCleared] = useState(false);
  const [enableButtons, setEnableButtons] = useState(true);
  const [isLeanAndExinityAmountUpdated, setIsLeanAndExinityAmountUpdated] = useState(false);
  const [isReconStatusUpdated, setIsReconStatusUpdated] = useState<boolean>(false);
  const [isCommentAdded, setIsCommentAdded] = useState<boolean>(false);

  const onClearFilters = () => {
    setFilterDates({ fromDate: '', toDate: '' });
    setIsFiltersCleared(true);
    setFilterQuery('');
    setIsFilterApplied(false);
    setIsFilterIconDisplay(false);
    enqueueSnackbar('All filters have been cleared successfully.', {
      variant: 'defaultAlert'
    });
    setTransactionsHistoryFilters((csf: any) => _.map(csf, (c) => {
      c.filters = _.map(c.filters, (f: any) => {
        if (f.isChecked) {
          f.isChecked = false;
        }
        return f;
      });
      return c;
    }));
    setIsFilterCleared(true);
  };

  const onReconButtonClick = (e: MouseEvent<HTMLDivElement>) => {
    const type = (e.target as HTMLDivElement).dataset.filter;
    setSelectedButtonType(type);
  };
  

  useEffect(() => {
    const newFilters = _.map(transactionsHistoryFilters, o => {
      if (_.findIndex(o.filters, (f: any) => f.isChecked) !== -1) {
        return true;
      }
      return false;
    });
    const isEnabled = _.compact(newFilters)[0];

    if (isFiltersCleared && !isEnabled) {
      setEnableButtons(true);
    } else if (isEnabled === true) {
      setEnableButtons(false);
    } else if (filterDates && filterDates.fromDate && filterDates.toDate) {
      setEnableButtons(false);
    } else {
      setEnableButtons(true);
    }
  }, [transactionsHistoryFilters, isFiltersCleared, filterDates]);

  useEffect(() => {
    setPage(isUpdatePage ? 0 : page);
    getDWTransactions.request(page + 1, rowsPerPage, order === 'asc' ? 1 : -1, orderBy, filterDates.fromDate, filterDates.toDate, filterQuery).then((res) => {
      if (res.status === 200) {
        const { totalcount } = res.headers;
        setTotalCount(parseInt(totalcount));
        setData((ds: any) => {
          return _.map(res.data, (d) => {
            d.leanAmountEdit = false;
            d.exinityAmountEdit = false;
            d.leanAmountHover = false;
            d.exinityAmountHover = false;
            return d;
          });
        });
        setIsDateFilterApplied(false);

        if (isFilterApplied) {
          enqueueSnackbar('Filters have been applied successfully.', {
            variant: 'successWithUndo',
            onUndo: onClearFilters
          });
          setIsFilterApplied(false);
        }
      }
      if (isUpdatePage) setIsUpdatePage(false);
      if (isFilterCleared) setIsFilterCleared(false);
      if (isFiltersCleared) setIsFiltersCleared(false);
      if (isLeanAndExinityAmountUpdated) setIsLeanAndExinityAmountUpdated(false);
      if (isReconStatusUpdated) setIsReconStatusUpdated(false);
      if (isCommentAdded) setIsCommentAdded(false);
    });
  }, [page, rowsPerPage, order, orderBy, isFilterCleared, filterQuery, isFiltersCleared, isUpdatePage, isDateFilterApplied, isLeanAndExinityAmountUpdated, isReconStatusUpdated, isCommentAdded]);

  useEffect(() => {
    if (!selectedButtonType) {
      setSelectedButtonType('DW_TRANSACTIONS');
    }
  }, [selectedButtonType]);

  const getTransactionsView = () => {
    if (selectedButtonType === 'DW_TRANSACTIONS' ) {
      return <DWTransactions />
    } else if (selectedButtonType === 'WALLET_TRANSACTIONS') {
      return <WalletTransactions />
    }  else if (selectedButtonType === 'NI_FUND_RECEIVED') {
      return <NIFundReceived />
    } else {
      return null;
    }
  };

  return (
    <CssContainer>
      <CssFlexBox sx={{justifyContent: 'space-between'}}>
        <CssTitle variant='h4' underline>Daily Reconciliation</CssTitle>
      </CssFlexBox>
      <Box sx={{display: 'flex', justifyContent: 'flex-start', mb: 2}}>
        <ButtonGroup variant='light' data={[
          {
            label: 'DW transactions',
            type: 'DW_TRANSACTIONS',
            onClick: onReconButtonClick
          },
          {
            label: 'Wallet transactions',
            type: 'WALLET_TRANSACTIONS',
            onClick: onReconButtonClick
          },
          {
            label: 'NI fund received',
            type: 'NI_FUND_RECEIVED',
            onClick: onReconButtonClick
          },
          {
            label: 'NI transactions'
          }
        ]} />
      </Box>
      {getTransactionsView()}
    </CssContainer>
  );
};

export default TransactionsHistory;