
export const InstrumentsCfdProfileDetails = [
  {
    name: "bid",
    label: "Bid",
    value: "",
    displayZero: true
  },
  {
    name: "offer",
    label: "Offer",
    value: "",
    displayZero: true
  },
  {
    name: "changeValue",
    label: "Change",
    value: "",
    displayZero: true
  },
  {
    name: "changePercentage",
    label: "Change %",
    value: "",
    displayZero: true
  },
  {
    name: "priorClose",
    label: "Prior close",
    value: "",
  },
  {
    name: "calcMode",
    label: "Calc mode",
    value: "",
  },
  {
    name: "freeDays",
    label: "Swap free days",
    value: "",
    displayZero: true
  },
  {
    section: "timeSession",
    title: "Time session",
    fields: [
      {
        name: "day",
        label: "Day",
        value: "MONDAY",
        fieldType: "select",
        wrapElement: true,
        noEdit: true,
        menuItems: [
          {
            value: "MONDAY",
            displayName: "Monday"
          },
          {
            value: "TUESDAY",
            displayName: "Tuesday"
          },
          {
            value: "WEDNESDAY",
            displayName: "Wednesday"
          },
          {
            value: "THURSDAY",
            displayName: "Thursday"
          },
          {
            value: "FRIDAY",
            displayName: "Friday"
          }
        ]
      },
      {
        name: "timeFrom",
        label: "Time from",
        value: "",
        isTime: true,
        pickValuesFrom: ["timeSession[0].timeFrom"]
      },
      {
        name: "timeTo",
        label: "Time to",
        value: "",
        pickValuesFrom: ["timeSession[0].timeTo"]
      },
    ]
  },
  {
    section: "holidaySession",
    title: "Holiday session",
    fields: [
      {
        name: "date",
        label: "Date",
        value: "",
        fieldType: "select",
        wrapElement: true,
        noEdit: true,
        menuItems: []
      },
      {
        name: "timeFrom",
        label: "Time from",
        value: "",
        isTime: true,
        pickValuesFrom: ["holidaySession[0].timeFrom"]
      },
      {
        name: "timeTo",
        label: "Time to",
        value: "",
        pickValuesFrom: ["holidaySession[0].timeTo"]
      },
    ]
  },
  {
    name: "quoteMode",
    label: "Quote mode",
    value: ""
  },
  {
    name: "executionMode",
    label: "Execution mode",
    value: ""
  },
  {
    name: "quoteDelayPeriod",
    label: "Quote delay period",
    value: "",
    displayZero: true
  },
  {
    name: "tickSize",
    label: "Tick size",
    value: "",
    displayZero: true
  },
  {
    name: "tickValue",
    label: "Tick value",
    value: "",
    displayZero: true
  },
  {
    name: "stopsLevel",
    label: "Stops level",
    value: "",
    displayZero: true
  },
  {
    name: "gtcMode",
    label: "GTC mode",
    value: ""
  },
  {
    name: "swapMode",
    label: "Swap mode",
    value: ""
  },
  {
    name: "swapLong",
    label: "Swap long",
    value: ""
  },
  {
    name: "swapShort",
    label: "Swap short",
    value: "",
    displayZero: true
  },
  {
    name: "baseCurrency",
    label: "1st currency (Base CCY)",
    value: ""
  },
  {
    name: "profitCurrency",
    label: "2nd (PL currency)",
    value: ""
  },
  {
    name: "marginCurrency",
    label: "Margin currency",
    value: ""
  },
  {
    section: "nemoApp",
    title: "Nemo app",
    fields: [
      {
        name: "description",
        label: "Common name",
        value: "",
        fullWidth: true
      },
      {
        name: "displayDescription",
        label: "Market description",
        value: "",
        isEdit: false,
        fullWidth: true
      },
      {
        name: "instrumentType",
        label: "Instrument type",
        value: ""
      },
      {
        name: "instrumentSubType",
        label: "2nd Instrument type",
        value: "",
        fieldType: "select",
        isEdit: false,
        menuItems: []
      },
      {
        name: "tags",
        label: "Tags",
        value: "",
        fullWidth: true,
        isEdit: false,
        isAutocomplete: true
      },
      {
        name: "internalStatus",
        label: "Internal status",
        value: "",
        fieldType: "select",
        menuItems: [
          {
            value: "ACTIVE",
            displayName: "Visible"
          },
          {
            value: "INACTIVE",
            displayName: "Disable"
          }
        ]
      },
      {
        name: "shortCode",
        label: "Short code",
        value: "",
        isEdit: false
      },
      {
        name: "tradingMode",
        label: "Trading mode",
        value: ""
      },
      {
        name: "leverage",
        label: "Leverage",
        value: "",
        isEdit: false,
        valueType: "INTEGER"
      },
      {
        name: "mtGroup",
        label: "Group name",
        value: "",
        fieldType: "select",
        isEdit: false,
        menuItems: []
      },
    ]
  }
];