export const SettlementReportsHeaders = {
  columns:  [
    {
      key: 'settlementId',
      label: 'Report ID'
    },
    {
      key: 'startDate',
      label: 'Report date',
      withTimestamp: true
    }
  ],
  sortableColumns: ['settlementId', 'type', 'startDate'],
  dateColumns: ['startDate'],
  searchColumns: ['settlementId', 'type'],
  actions: [
    {
      key: 'DOWNLOAD',
      action: () => {}
    }
  ],
  actionCellStyles: {
    width: '36%'
  },
  paginationColSpan: 5,
  filterAlign: 'right'
};