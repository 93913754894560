import React, { useState, useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { FormControl, Box, Button, Select } from '@mui/material';
import Time from './icons/Time';
import { GlobalContext } from 'context';
import { capitalizeFirstLetter } from 'utils';

interface TimePickerProps {
  pickerType: string;
  id?: string;
  defaultTime?: string; 
  isStaticTimeEnabled?: Boolean;
}

const StyledSelect = styled(Select)({
  borderRadius: '30px',
  color: '#000',
  '& .MuiOutlinedInput-root': {
    borderRadius: '30px',
    color: '#000',
  },
  '& .MuiSelect-select': {
    padding: '6px 12px',
  },
  '& .MuiSelect-icon': {
    right: 12,
    top: 6,
  },
  '& fieldset': {
    borderColor: '#000',
    '& legend': {
      display: 'none',
    },
  },
  '& .MuiSelect-nativeInput': {
    bottom: '9px',
    left: '12px',
    position: 'absolute',
    pointerEvents: 'none',
    width: '100%',
    opacity: 2,
    boxSizing: 'border-box',
    border: 'none',
    fontSize: 'medium',
  },
});

const StyledScrollContainer = styled(Box)(({ isLast }: { isLast?: boolean }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '200px',
  overflowY: 'hidden',
  '&:hover': {
    overflowY: 'auto',
  },
  '::-webkit-scrollbar': {
    width: '8px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: 'grey',
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#0056b3',
  },
  flex: 1,
  borderRight: !isLast ? '1px solid lightgray' : 'none',
}));

const StaticTimePickerComponent = (props: TimePickerProps) => {
  const { pickerType, id ,defaultTime} = props;
  const [hour, setHour] = useState<number>(2); 
  const [minute, setMinute] = useState<number>(48); 
  const [amPm, setAmPm] = useState<'AM' | 'PM'>('PM'); 

  const [time, setTime] = useState<string>(defaultTime || '')
  const { paymentFilterDates, setPaymentFilterDates, isFilterCleared } = useContext(GlobalContext);
  const [openDropdown, setOpenDropdown] = useState(false);

  const updateTime = (newHour: number, newMinute: number, newAmPm: 'AM' | 'PM') => {
    const timeString = `${newHour}:${newMinute < 10 ? `0${newMinute}` : newMinute} ${newAmPm}`;
    setTime(timeString);

    let timeFiltersData = { ...paymentFilterDates };
    const key = id ? `${id}${capitalizeFirstLetter(pickerType)}Time` : `${pickerType}Time`;
    timeFiltersData[key] = timeString;
    setPaymentFilterDates(timeFiltersData);
  };

  const handleConfirm = () => {
    setOpenDropdown(false);
  };

  useEffect(() => {
    if (isFilterCleared) {
      setPaymentFilterDates({
        ...paymentFilterDates,
        statusUpdatedFromDate: '',
        statusUpdatedToDate: '',
        statusUpdatedFromDateTime: '',
        statusUpdatedToDateTime: '',
      });
      setTime(defaultTime || '');
    }
  }, [isFilterCleared, setPaymentFilterDates, paymentFilterDates]);

  console.log("paymentFilers",paymentFilterDates,time)
  return (
    <FormControl sx={{ width: '50%', top: 3, ml: 1.5 }}>
      <StyledSelect
        label='Time'
        value={time ? time : ((paymentFilterDates && id) ? paymentFilterDates[`${id}${capitalizeFirstLetter(pickerType)}Time`] : paymentFilterDates[`${pickerType}Time`]) || ''}
        onClick={() => setOpenDropdown((prev) => !prev)}
        IconComponent={Time}
        readOnly
        sx={{
          cursor: 'pointer',
          backgroundColor: '#fff',
        }}
      >
      </StyledSelect>

      {openDropdown && (
        <Box
          sx={{
            position: 'absolute',
            backgroundColor: 'white',
            border: '1px solid #000',
            padding: 1,
            top: 45,
            zIndex: 1000,
            width: '10rem',
            borderRadius: '8px',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <StyledScrollContainer>
              {Array.from({ length: 12 }, (_, i) => i + 1).map((h) => (
                <Box
                  key={h}
                  sx={{
                    padding: '5px 10px',
                    cursor: 'pointer',
                    backgroundColor: h === hour ? '#007bff' : 'transparent',
                    color: h === hour ? '#fff' : 'black',
                    '&:hover': { backgroundColor: '#f0f0f0' },
                  }}
                  onClick={() => {
                    setHour(h);
                    updateTime(h, minute, amPm);
                  }}
                >
                  {h < 10 ? `0${h}` : h}
                </Box>
              ))}
            </StyledScrollContainer>

            <StyledScrollContainer>
              {Array.from({ length: 60 }, (_, i) => i).map((m) => (
                <Box
                  key={m}
                  sx={{
                    padding: '5px 10px',
                    cursor: 'pointer',
                    backgroundColor: m === minute ? '#007bff' : 'transparent',
                    color: m === minute ? '#fff' : 'black',
                    '&:hover': { backgroundColor: '#f0f0f0' },
                  }}
                  onClick={() => {
                    setMinute(m);
                    updateTime(hour, m, amPm);
                  }}
                >
                  {m < 10 ? `0${m}` : m}
                </Box>
              ))}
            </StyledScrollContainer>

            <StyledScrollContainer isLast>
              {['AM', 'PM'].map((ampm) => (
                <Box
                  key={ampm}
                  sx={{
                    padding: '5px 10px',
                    cursor: 'pointer',
                    backgroundColor: ampm === amPm ? '#007bff' : 'transparent',
                    color: ampm === amPm ? '#fff' : 'black',
                    '&:hover': { backgroundColor: '#f0f0f0' },
                  }}
                  onClick={() => {
                    setAmPm(ampm as 'AM' | 'PM');
                    updateTime(hour, minute, ampm as 'AM' | 'PM');
                  }}
                >
                  {ampm}
                </Box>
              ))}
            </StyledScrollContainer>
          </Box>
          <Button
            onClick={handleConfirm}
            sx={{
              backgroundColor: 'transparent',
              float:'right',
              color: 'blue',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: '#f0f0f0',
              },
            }}
          >
            OK
          </Button>
        </Box>
      )}
    </FormControl>
  );
};

export default StaticTimePickerComponent;
